// слайдеры на главной
(function($){
    $(document).ready(function(){
      var swiper = new Swiper('.ms-c-js', {
        pagination: '.ms-pag-js',
        nextButton: '.ms-next-js',
        prevButton: '.ms-prev-js',
        slidesPerView: 1,
        grabCursor: true,
        // simulateTouch: false,
        autoplay: 5000,
        paginationClickable: true,
        paginationType: 'bullets',
        spaceBetween: 0,
        keyboardControl: true,
        loop: true
      });

      var swiperPartners = new Swiper('.ps-c-js', {
        //pagination: '.ms-pag-js',
        nextButton: '.ps-next-js',
        prevButton: '.ps-prev-js',
        slidesPerView: 4,
        grabCursor: false,
        // simulateTouch: false,
        autoplay: 5000,
        paginationClickable: true,
        paginationType: 'bullets',
        spaceBetween: 30,
        keyboardControl: true,
        loop: true,
        breakpoints: {
          991: {
            spaceBetween: 20,
            slidesPerView: 3
          },
          767: {
            spaceBetween: 15,
            slidesPerView: 2
          },
          543: {
            spaceBetween: 0,
            slidesPerView: 1
          }
        }
      });
    });
})(jQuery);


